import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import React from 'react'
import { InjectedProps } from './types'

interface Props extends InjectedProps {
  title: string
  hideCloseButton?: boolean
  onBack?: () => void
  bodyPadding?: string
}

const styles = (theme: any) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      // position: 'absolute !important',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant='h5'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const Modal: React.FC<Props> = ({ title, onDismiss }) => (
  <>
    <Dialog open aria-labelledby='customized-dialog-title'>
      {/*     <DialogTitle
      id='customized-dialog-title'
      onClose={() => onDismiss && onDismiss()}
    >
      {title}
    </DialogTitle>
    <DialogContent dividers>{children}</DialogContent> */}
    </Dialog>
  </>
)

export default Modal
