import { capitalCase } from 'change-case'
// material
import { Box, Card, Container, Stack, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// routes
// hooks
import useAuth from '../../hooks/useAuth'
// layouts
// components
import { MHidden } from '../../components/@material-extend'
import { LoginForm } from '../../components/authentication/login'
import Page from '../../components/Page'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#10193a',
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth()

  return (
    <RootStyle title='Login | Minimal-UI'>
      <MHidden width='mdDown'>
        <SectionStyle>
          <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5, color: '#fff' }}>
            Hi, Welcome Back
          </Typography>
          <img src='/static/illustrations/illustration_login.png' alt='login' />
        </SectionStyle>
      </MHidden>

      <Container maxWidth='sm'>
        <ContentStyle>
          <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h4' gutterBottom>
                Sign in to Nitroxpad
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Enter your details below.
              </Typography>
            </Box>

            <Tooltip title={capitalCase(method)}>
              <Box
                component='img'
                src={`/static/auth/ic_${method}.png`}
                sx={{ width: 32, height: 32 }}
              />
            </Tooltip>
          </Stack>

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
