// material
import { Box, BoxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme()
  const PRIMARY_LIGHT = theme.palette.primary.light
  const PRIMARY_MAIN = theme.palette.primary.main
  const PRIMARY_DARK = theme.palette.primary.dark

  return (
    <Box sx={{ ...sx }}>
      <Box component='img' src='/static/brand/logo.png' alt='' />
    </Box>
  )
}
