import { useWallet } from '@binance-chain/bsc-use-wallet'
import { Box, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useModal } from '../../contexts/Modal'
import { formatAddress } from '../../utils/address'
import { Web3ConnectModal } from '../Web3ConnectModal'

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'none',
  },
}))

export const Web3ConnectButton = ({ useWalletIcon, ...props }: any) => {
  const classes = useStyles()
  const [showConnectModal] = useModal(<Web3ConnectModal />)
  const { account, reset, connect, status } = useWallet()
  console.log(account)
  if (!account && status === 'disconnected') {
    const web3 = localStorage.getItem('web3')
    if (web3 === 'injected') connect('injected')
    else if (web3 === 'walletconnect') connect('walletconnect')
  }

  return (
    <>
      <Button
        variant='contained'
        startIcon={<Box component='img' src='/img/wallet_ic.svg' alt='' />}
        className='def_blk_btn'
        onClick={
          !!account
            ? () => {
                localStorage.removeItem('web3')
                reset()
              }
            : showConnectModal
        }
      >
        {/* <Box component='img' src='/images/cnnctwlt_ic.svg' alt='' /> */}
        {!!account ? formatAddress(account) : 'Connect Wallet'}
      </Button>
    </>
  )
}
