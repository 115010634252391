// routes
import Router from './routes'
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// components
import { ProgressBarStyle } from './components/LoadingScreen'
import NotistackProvider from './components/NotistackProvider'
import RtlLayout from './components/RtlLayout'
import ScrollToTop from './components/ScrollToTop'
import ThemePrimaryColor from './components/ThemePrimaryColor'
import Providers from './Providers'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Providers>
      <ThemeConfig>
        <ThemePrimaryColor>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              {/* <Settings /> */}
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </RtlLayout>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Providers>
  )
}
