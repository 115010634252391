// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::placeholder': {
            color: theme.palette.text.disabled,
          },
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms',
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)',
        },
        '.frm_box': {
          marginTop: '30px',
          background: '#fff',
          borderRadius: '15px',
          padding: '25px 30px',
          color: '#000',
        },
        '.frm_box h3': {
          fontFamily: 'Play',
          fontSize: '30px',
          lineHeight: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: '20px',
        },
        '.frm_box .MuiFormControl-root': {
          width: '100%',
        },
        '.frm_box .MuiFormLabel-root.Mui-focused': {
          color: 'rgb(32 93 231)',
        },
        '.frm_box .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(32 93 231)',
        },
        '.inlnfrmfld': {
          display: 'flex',
          alignItems: 'center',
        },
        '.frm_box .inlnfrmfld .MuiFormControl-root ': {
          width: '85px',
          margin: '0 5px',
        },
        '.inlnfrmfld .c_label': {
          fontSize: '15px',
          marginRight: '10px',
          color: '#686868',
        },
        '.inlnfrmfld h6 ': {
          fontSize: '15px',
          color: '#000',
        },
        '.cstmfrmcntrl': {
          margin: '0 !important',
        },
        '.cstmfrmcntrl .PrivateNotchedOutline-legendLabelled-13 > span': {
          minWidth: '44px',
        },
        '.fns_bx': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
        },
        '.fns_bx .MuiSvgIcon-root': {
          color: '#46b53f',
          height: '80px',
          width: '80px',
        },
        '.fns_bx h2': {
          fontFamily: 'Play',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#000',
          marginBottom: '20px',
        },
        '.fns_bx .btn_next': {
          borderRadius: '4px',
          fontWeight: 'bold',
        },
        '.stpr_actions': {
          padding: '0 15px',
        },
        '.defcstmfld': {
          width: '80%',
        },
        // @media screen and (max-width: 575px) {
        //   .inlnfrmfld {
        //     flex-direction: column;
        //   }
        //   .inlnfrmfld .c_label {margin-right: 0px;}
        // }
      }}
    />
  );
}
