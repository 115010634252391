import { Suspense, lazy } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
import MainLayout from '../layouts/main'
// components
import LoadingScreen from '../components/LoadingScreen'
import AuthGuard from '../guards/AuthGuard'
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('/dashboard')

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/dashboard/one' replace /> },
        { path: 'one', element: <PageOne /> },
        { path: 'projects', element: <PageTwo /> },
        { path: ':id/edit', element: <PageOne /> },
        { path: 'launch/:id', element: <LaunchPage /> },
        { path: 'whitelist/:id', element: <WhiteListPage /> },
        { path: 'airdrop', element: <AirdropPage /> },
        {
          path: 'app',
          children: [
            { element: <Navigate to='/dashboard/app/four' replace /> },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <Navigate to='dashboard' replace /> }],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}

// IMPORT COMPONENTS

// Dashboard
const PageOne = Loadable(lazy(() => import('../pages/dashboard/Dashboard')))
const PageTwo = Loadable(lazy(() => import('../pages/dashboard/UserList')))

const LaunchPage = Loadable(lazy(() => import('../pages/LaunchPage')))
const WhiteListPage = Loadable(lazy(() => import('../pages/whitelist')))
const AirdropPage = Loadable(lazy(() => import('../pages/airdrop')))
const PageFour = Loadable(lazy(() => import('../pages/PageFour')))
const PageFive = Loadable(lazy(() => import('../pages/PageFive')))
const PageSix = Loadable(lazy(() => import('../pages/PageSix')))
const NotFound = Loadable(lazy(() => import('../pages/Page404')))
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')))
