import React, { ReactNode } from 'react';
import * as bsc from '@binance-chain/bsc-use-wallet';
import { getRpcUrl } from './utils';
import { ModalProvider } from './contexts/Modal';

type ProvidersProps = {
  children: ReactNode;
};

const Providers: React.FC<ProvidersProps> = ({ children }: ProvidersProps): JSX.Element => {
  const rpcUrl = getRpcUrl();
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID || '56', 10); // Default to BSC mainnet if not set

  if (!rpcUrl) {
    throw new Error('RPC URL is not defined');
  }

  return (
    <bsc.UseWalletProvider
      chainId={chainId}
      connectors={{
        walletconnect: { rpcUrl },
        bsc,
      }}
    >
      <ModalProvider>{children}</ModalProvider>
    </bsc.UseWalletProvider>
  );
};

export default Providers;
