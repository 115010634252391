import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Flex } from '../Flex'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    fontSize: '1.5rem',
  },
  title: {
    margin: 0,
    padding: 0,
  },
  modal: {},
}))

export interface ModalProps {
  isOpen?: boolean
  onDismiss?: () => void
  title?: string
  maxWidth?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  scroll?: 'paper' | 'body'
  restrict?: boolean
  children?: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onDismiss,
  title,
  maxWidth,
  fullWidth,
  scroll,
  children, // Destructure children
  restrict,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={!!isOpen}
      onClose={onDismiss}
      maxWidth={maxWidth || 'md'}
      fullWidth={!!fullWidth}
      scroll={scroll || undefined}
      classes={{ root: classes.modal }}
      disableEscapeKeyDown={!restrict}
    >
      <Box p={2}>
        {!!title && (
          <Flex column>
            <Flex justify='space-between' align='center' m={1} marginBottom={2}>
              <Typography variant='h5' align='left' className={classes.title}>
                <b>{title}</b>
              </Typography>
              {!restrict && (
                <IconButton
                  aria-label='close'
                  onClick={onDismiss}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Flex>
            <Divider />
          </Flex>
        )}

        <Box mt={2}>{children}</Box>
      </Box>
    </Dialog>
  )
}
