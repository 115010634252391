import React, { createContext, ReactNode, useState } from 'react'

interface ModalsContext {
  onPresent: (node: ReactNode, key?: string) => void
  onDismiss: () => void
  setCloseOnOverlayClick: React.Dispatch<React.SetStateAction<boolean>>
}

export const Context = createContext<ModalsContext>({
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
})

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalNode, setModalNode] = useState<ReactNode | null>(null)
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true)

  const handlePresent = (node: ReactNode) => {
    setModalNode(node)
    setIsOpen(true)
  }

  const handleDismiss = () => {
    setModalNode(null)
    setIsOpen(false)
  }

  return (
    <Context.Provider
      value={{
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        setCloseOnOverlayClick,
      }}
    >
      {isOpen &&
        modalNode &&
        React.isValidElement(modalNode) &&
        React.cloneElement(modalNode)}
      {children}
    </Context.Provider>
  )
}

export default ModalProvider
