const networkSettings: Record<number, { chainId: string; chainName: string; nativeCurrency: { name: string; symbol: string; decimals: number }; rpcUrls: string[]; blockExplorerUrls: string[] }> = {
    56: {
    chainId: '0x38',
    chainName: 'BSC  Mainnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  11155111: {
    chainId: '0xaa36a7',
    chainName: 'Sepolia Test Network',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://ethereum-sepolia.core.chainstack.com/af6d30854cab855fbb923bc6ed090caa',
    ],
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
  },
};

declare const window: any;

export const networkSetup = (chainId: any) => {
  return new Promise((resolve, reject) => {
    const provider = window.ethereum;
    if (provider) {
      if (networkSettings.hasOwnProperty(chainId)) {
        provider
          .request({
            method: 'wallet_addEthereumChain',
            params: [networkSettings[chainId]],
          })
          .then(resolve)
          .catch(reject);
      } else {
        reject(
          new Error(`No network settings configured for chainId: '${chainId}'`)
        );
      }
    } else {
      alert('Please Install Wallet');
      reject(new Error(`window.ethereum is '${typeof provider}'`));
    }
  });
};
